import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Section.module.scss'; // Import css modules stylesheet as styles

const Section = ({children, duration, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: `#${id}`,
            triggerHook: 0,
            duration: duration,
            offset: '-140'
        })
        .setPin(`#${id} .${styles.inner}`, { spacerClass: styles.aaa }) // pinSpacing verhindert zusätzlichen Abstand
        .addTo(controller);


        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <section id={id} className={styles.section}>
            <div id="inner1" className={styles.inner}>
                {children}
            </div>
        </section>
    );
};

export default Section;
