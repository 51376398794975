import React from 'react';
import Section from './components/Section';
import Intro from './steps/Intro';
import Beratung from './steps/Beratung';
import Operativ from './steps/Operativ';
import Autonom from './steps/Autonom';
import styles from './app.module.scss'; // Import css modules stylesheet as styles

function App() {
    return (
        <div className={styles.app}>
            <Section duration={3000} id="section1">
                <Intro />
            </Section>
            <Section duration={3000} id="section2">
                <Beratung />
            </Section>
            <Section duration={3000} id="section3">
                <Operativ />
            </Section>
            <Section duration={4000} id="section4">
                <Autonom />
            </Section>
        </div>
    );
}

export default App;
