import { animateScroll as scroll } from 'react-scroll';
const scrollToPosition = (position, duration = 1000) => {
    // window.scrollTo({
    //     top: position, 
    //     behavior: 'smooth' // Sorgt für einen sanften Scroll-Effekt
    // });

    let pos;

    if(typeof position === 'string') {
        if(position.search('#') !== -1) {
            window.top.location.href = position;
            return false;
        }
        else{
            const element = document.getElementById(position);
                if (element) {
                    pos = element.offsetTop;
                }
                else {
                    pos = 0;
                }
        }
    }
    else{
        pos = position;
    }


    scroll.scrollTo(pos, {
        duration,
        delay: 0,
        smooth: 'easeInOutQuart'
    });
};

export default scrollToPosition;