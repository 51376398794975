import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Operativ.module.scss'; // Import css modules stylesheet as styles
import Link from '../components/Link';
import Arrow from '../components/Arrow';

const Operativ = ({children, duration, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();

        // Dauer der Fixierung in Pixeln
        // var duration = 2000;


        new ScrollMagic.Scene({offset: 7600, reverse: true}).setClassToggle("#head_operativ", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 8000, reverse: true}).setClassToggle("#head_operativ", styles.top).addTo(controller);

         new ScrollMagic.Scene({offset: 8500, reverse: true}).setClassToggle("#oq1", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 9000, reverse: true}).setClassToggle("#oq2", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 9500, reverse: true}).setClassToggle("#oq3", styles.visible).addTo(controller);
 
         new ScrollMagic.Scene({offset: 9900, reverse: true}).setClassToggle("#linkOperativ", styles.visible).addTo(controller);
 



        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <div>
            <h1 id="head_operativ" className={styles.head_operativ}>
                    Operative Partnerschaft
            </h1>
            <div id="oq1" className={styles.question + ' ' + styles.oq1}>Monatsende erreicht und wieder ist niemand <br className={styles.hiddenL}/> für die Einnahmeaufteilung da?</div>
            <div id="oq2" className={styles.question + ' ' + styles.oq2}>Das System ist beschafft, <br className={styles.hiddenL}/> aber keine Mitarbeiter für den Betrieb?</div>
            <div id="oq3" className={styles.question + ' ' + styles.oq3}>Wer macht eigentlich<br/>eine Auswertung meiner Daten?</div>
            <div id="linkOperativ" className={styles.linkOperativ}><Link href="/partner">Erfahren Sie mehr!</Link></div>
            <div id="arrow4" class={styles.arrow4}><Arrow position={13900}/></div>
        </div>
    );
};

export default Operativ;
