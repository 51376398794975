import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Link.module.scss'; // Import css modules stylesheet as styles
import { ReactComponent as ArrowIcon } from '../img/arrow.svg';

const Link = ({children, href}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();


        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <a href={href} className={styles.link}>{children}</a>
    );
};

export default Link;
