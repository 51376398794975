import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Arrow.module.scss'; // Import css modules stylesheet as styles
import scrollToPosition from '../utils';
import { ReactComponent as ArrowIcon } from '../img/arrow.svg';

const Arrow = ({position, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();


        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <button id={id} name={id} className={styles.arrow} onClick={() => scrollToPosition(position)}><ArrowIcon /></button>
    );
};

export default Arrow;
