import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Beratung.module.scss'; // Import css modules stylesheet as styles
import { ReactComponent as Ecke } from '../img/ecke.svg';
import { ReactComponent as BeratungIcon } from '../img/beratung.svg';
import { ReactComponent as OperativIcon } from '../img/operativ.svg';
import { ReactComponent as PlugnplayIcon } from '../img/plugnplay.svg';
import Link from '../components/Link';
import Arrow from '../components/Arrow';

const Beratung = ({children, duration, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();

        // Dauer der Fixierung in Pixeln
        // var duration = 2000;

        new ScrollMagic.Scene({offset: 3400, duration: 11000, reverse: true}).setClassToggle("#ecke", styles.visible).addTo(controller);
        new ScrollMagic.Scene({duration: 3000, offset: 3600, reverse: true}).setClassToggle("#beratungIcon", styles.visible).addTo(controller);
        new ScrollMagic.Scene({duration: 3000, offset: 6600, reverse: true}).setClassToggle("#operativIcon", styles.visible).addTo(controller);
        new ScrollMagic.Scene({duration: 13000, offset: 9900, reverse: true}).setClassToggle("#plugnplayIcon", styles.visible).addTo(controller);

        new ScrollMagic.Scene({offset: 3600, reverse: true}).setClassToggle("#head_beratung", styles.visible).addTo(controller);
        new ScrollMagic.Scene({offset: 4000, reverse: true}).setClassToggle("#head_beratung", styles.top).addTo(controller);

        new ScrollMagic.Scene({offset: 4500, reverse: true}).setClassToggle("#bq1", styles.visible).addTo(controller);
        new ScrollMagic.Scene({offset: 5000, reverse: true}).setClassToggle("#bq2", styles.visible).addTo(controller);
        new ScrollMagic.Scene({offset: 5500, reverse: true}).setClassToggle("#bq3", styles.visible).addTo(controller);

        new ScrollMagic.Scene({offset: 5900, reverse: true}).setClassToggle("#linkBeratung", styles.visible).addTo(controller);



        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <div>
            <div id="ecke" className={`${styles.ecke}`}>
                <Ecke className={`${styles.eckeInner}`}/>
                <div id="beratungIcon" className={`${styles.beratungIcon}`} >
                    <BeratungIcon />
                </div>
                <div id="operativIcon" className={`${styles.operativIcon}`} >
                    <OperativIcon />
                </div>
                <div id="plugnplayIcon" className={`${styles.plugnplayIcon}`} >
                    <PlugnplayIcon />
                </div>
            </div>
            <h1 id="head_beratung" className={styles.head_beratung}>
                    Beratung und Unterstützung
            </h1>
            <div id="bq1" className={styles.question + ' ' + styles.bq1}>Wie passt eine automatische Fahrgastzählanlage<br className={styles.hiddenL}/>in meinen Betrieb und wie viele brauche ich davon?</div>
            <div id="bq2" className={styles.question + ' ' + styles.bq2}>Schon von (((etiCORE gehört?</div>
            <div id="bq3" className={styles.question + ' ' + styles.bq3}>Lust auf Mobilitätswende,<br/>aber noch keine Strategie?</div>
            <div id="linkBeratung" className={styles.linkBeratung}><Link href="/beratung">Erfahren Sie mehr!</Link></div>
            <div id="arrow3" class={styles.arrow3}><Arrow position={9900}/></div>
        </div>
    );
};

export default Beratung;
