import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Autonom.module.scss'; // Import css modules stylesheet as styles
import Link from '../components/Link';
import Arrow from '../components/Arrow';

const Autonom = ({children, duration, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();

        // Dauer der Fixierung in Pixeln
        // var duration = 2000;


         new ScrollMagic.Scene({offset: 11600, reverse: true}).setClassToggle("#head_autonom", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 12000, reverse: true}).setClassToggle("#head_autonom", styles.top).addTo(controller);

         new ScrollMagic.Scene({offset: 12500, reverse: true}).setClassToggle("#oq1", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 13000, reverse: true}).setClassToggle("#oq2", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 13500, reverse: true}).setClassToggle("#oq3", styles.visible).addTo(controller);
 
         new ScrollMagic.Scene({offset: 13900, reverse: true}).setClassToggle("#linkAutonom", styles.visible).addTo(controller);
 



        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);



    return (
        <div>
            <h1 id="head_autonom" className={styles.head_autonom}>
                    Autonome Projektumsetzung
            </h1>
            <div id="oq1" className={styles.question + ' ' + styles.oq1}>System beauftragt und schon vor dem<br className={styles.hiddenL}/>Projektstart im Stillstand?</div>
            <div id="oq2" className={styles.question + ' ' + styles.oq2}>Systemerweiterung beauftragt,<br className={styles.hiddenL}/>doch niemand da, der weiß, wie sie passen soll?</div>
            <div id="oq3" className={styles.question + ' ' + styles.oq3}>Neue Technik ist beschafft, aber keine<br className={styles.hiddenL}/>Idee, wie sie in den Betrieb passt?</div>
            <div id="linkAutonom" className={styles.linkAutonom}><Link href="/umsetzung">Erfahren Sie mehr!</Link></div>
            <div id="arrow4" class={styles.arrow4}><Arrow position={'#anchor_c090b1f6_Mobilitaet-der-Zukunft'}/></div>
        </div>
    );
};

export default Autonom;
